import React, { useEffect, useState } from "react";
import advice from "../../assets/svgs/advice.svg";
import users1 from "../../assets/svgs/users.svg";
import premium from "../../assets/svgs/premium.svg";
import analytics from "../../assets/svgs/analytics.svg";
import redirect from "../../assets/svgs/redirect.svg";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../../supabase/supabaseClient";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import "firebase/analytics";
import AnalyticsChart from "./AnalyticsChart";
import constants from "../../utils/constants";

const DashBoard = () => {
  const [users, setUsers] = useState([]);
  const [advices, setAdvices] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getUsers();
    getAdvice();
  }, []);

  const getAdvice = async () => {
    setLoading(true);
    const { data, error } = await supabase.from("dsa_advices").select("*");
    if (!error) {
      setLoading(false);
      // console.log("advice data: ", data);
      const filteredData = data.filter((item) => !item.deleted);
      setAdvices(filteredData);
    } else {
      setLoading(false);
      console.log("error while fetching advice data: ", error);
    }
  };

  const getUsers = async () => {
    setLoading(true);
    const { data, error } = await supabase
      .from("dsa_users")
      .select("*")
      .eq("deleted", false);
    if (!error) {
      setLoading(false);
      // console.log("user data: ", data);
      const filteredData = data.filter(
        (item) => !item.deleted && item.role !== "Admin"
      );
      setUsers(filteredData);
    } else {
      setLoading(false);
      console.log("error while fetching user data: ", error);
    }
  };

  const filterPre = users.filter((pre) => pre.is_premium);

  return (
    <div className="dsa__advice">
      <div className="dsa___dashboard ">
        <div className="title">Dashboard</div>
      </div>
      <div className="dsa__dashlist p-3">
        <div className="row">
          <div className="col-xl-3 col-sm-6 col-12 mb-xl-0 mb-4">
            <div className="card text-white">
              <div className="card-body ps-4">
                <img src={advice} className="card-img-top" alt="advice-icon" />
                <p className="card-text">Total Advice</p>
                <h5 className="card-title">{advices.length}</h5>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12 mb-xl-0 mb-4">
            <div className="card text-white">
              <div className="card-body ps-4">
                <img src={users1} className="card-img-top" alt="advice-icon" />
                <p className="card-text">Total Users </p>
                <h5 className="card-title">{users.length}</h5>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12 mb-xl-0 mb-4">
            <div className="card text-white">
              <div className="card-body ps-4">
                <img src={premium} className="card-img-top" alt="advice-icon" />
                <p className="card-text">Premium Users</p>
                <h5 className="card-title">{filterPre.length}</h5>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12 mb-xl-0 mb-4">
            <div className="card text-white h-100">
              <div className="card-body d-flex justify-content-between flex-column ps-4">
                <img
                  src={analytics}
                  className="card-img-top"
                  alt="advice-icon"
                />
                <div className="analytics_btn w-100 d-flex justify-content-between align-items-center">
                  <p className="card-text m-0">Analytics</p>
                  <Link
                    href=""
                    to="https://console.firebase.google.com/u/1/project/daily-security-advice-a7dda/analytics/app/android:com.daily_security_advice/overview/~2F%3Ft%3D1687758761858&fpn%3D663674325737&swu%3D1&sgu%3D1&cs%3Dapp.m.dashboard.overview&g%3D1"
                  >
                    <img src={redirect} width={20} alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="d-flex bg-dark">
            <p>test me</p>
            <AnalyticsChart />
      </div> */}
    </div>
  );
};

export default DashBoard;
