import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo-ir.png";
import logo_teen from "../../assets/images/dashboard.png";
import bell from "../../assets/svgs/bell.svg";
import bars from "../../assets/svgs/toggle_bars.svg";
import profile from "../../assets/svgs/profile.jpg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import constants from "../../utils/constants";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { supabase } from "../../supabase/supabaseClient";

const Sidebar = () => {
  const { pathname } = useLocation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  const logout = async () => {
    const { data, error } = await supabase.auth.signOut();
    if (!error) {
      localStorage.removeItem(constants.USER);
      localStorage.setItem(constants.CURRENT_PANEL, "dsa");
      document.body.classList.remove("dsa_teen_body");
      navigate("/login");
    } else throw new Error(error);
  };

  const removeClass = (element) => {
    for (let i = 0; i <= 6; i++) {
      element[i]?.classList.remove("active");
    }
  };

  useEffect(() => {
    if (pathname === "/" || pathname === "/teen-dashboard") {
      removeClass(document.getElementsByClassName("link"));
      document.getElementsByClassName("dashboard")[0]?.classList.add("active");
    } else if (
      pathname.includes("/advice") ||
      pathname.includes("/teen-advice")
    ) {
      removeClass(document.getElementsByClassName("link"));
      document.getElementsByClassName("advice")[0]?.classList.add("active");
    } else if (
      pathname.includes("/users") ||
      pathname.includes("/teen-users")
    ) {
      removeClass(document.getElementsByClassName("link"));
      document.getElementsByClassName("users")[0]?.classList.add("active");
    }
  }, [pathname]);

  return (
    <>
      <aside className="dsa__dashboard">
        <div className="dsa__dashboard-wrapper">
          <div className="dsa__dash-logo">
            <Link
              to={
                localStorage.getItem(constants.CURRENT_PANEL) === "dsa"
                  ? "/"
                  : "/teen-dashboard"
              }
              className="d-flex align-items-center text-decoration-none"
            >
              <img
                src={
                  localStorage.getItem(constants.CURRENT_PANEL) === "dsa"
                    ? logo
                    : logo_teen
                }
                alt="logo"
                className={
                  localStorage.getItem(constants.CURRENT_PANEL) === "dsa"
                    ? "w-25 h-25"
                    : "w-25 h-25 rounded-circle"
                }
              />
              <p className="text-white fw-bold fs-5 m-0 ms-3">
                {localStorage.getItem(constants.CURRENT_PANEL) === "dsa"
                  ? "DSA Admin"
                  : "TGSA Admin"}
              </p>
            </Link>
          </div>
          <ul className="dsa__dash-menu">
            <li>
              <Link
                to={
                  localStorage.getItem(constants.CURRENT_PANEL) === "dsa"
                    ? "/"
                    : "/teen-dashboard"
                }
                className="link dashboard"
              >
                Dashboard
              </Link>
            </li>
            <li>
              <Link
                to={
                  localStorage.getItem(constants.CURRENT_PANEL) === "dsa"
                    ? "/advice"
                    : "/teen-advice"
                }
                className="link advice"
              >
                Advice
              </Link>
            </li>
            <li>
              <Link
                to={
                  localStorage.getItem(constants.CURRENT_PANEL) === "dsa"
                    ? "/users"
                    : "/teen-users"
                }
                className="link users"
              >
                Users
              </Link>
            </li>
            <li>
              <Link onClick={logout} className="link logout">
                Logout
              </Link>
            </li>
          </ul>
        </div>
      </aside>

      <div className="dsa__dash-header">
        <div className="dsa__dash-header-wrapper">
          <div className="ra_dash-head_right">
            <div className="dsa__dash-head_profile">
              <Button onClick={handleShow}>
                <img src={bars} alt="toggle-btn" className="bars" />
              </Button>

              <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Offcanvas</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <div className="dsa__dashboard teen_sec">
                    <div className="dsa__dashboard-wrapper">
                      <div className="dsa__dash-logo">
                        <img src={logo} alt="logo" />
                      </div>
                      <ul className="dsa__dash-menu">
                        <li>
                          <Link
                            to={
                              localStorage.getItem(constants.CURRENT_PANEL) ===
                              "dsa"
                                ? "/"
                                : "/teen-dashboard"
                            }
                            className="link dashboard"
                          >
                            Dashboard
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={
                              localStorage.getItem(constants.CURRENT_PANEL) ===
                              "dsa"
                                ? "/advice"
                                : "/teen-advice"
                            }
                            className="link advice"
                            onClick={handleClose}
                          >
                            Advice
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={
                              localStorage.getItem(constants.CURRENT_PANEL) ===
                              "dsa"
                                ? "/users"
                                : "/teen-users"
                            }
                            className="link users"
                            onClick={handleClose}
                          >
                            Users
                          </Link>
                        </li>
                        <li>
                          <Link to="/login" onClick={logout}>
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
            </div>
          </div>

          {/* <div className="ra_dash-head_left">
            <div className="dsa__dash-head_profile">
              <Link to="">
                <img src={profile} alt="profile" />
              </Link>
            </div>
          </div> */}
          <div className="ra_dash-head_left">
            <select
              className="category_dropdown form-select"
              name="panel type"
              value={localStorage.getItem(constants.CURRENT_PANEL)}
              onChange={(e) => {
                localStorage.setItem(constants.CURRENT_PANEL, e.target.value);
                if (e.target.value === "dsa") {
                  document.body.classList.remove("dsa_teen_body");
                  navigate("/");
                } else {
                  document.body.classList.add("dsa_teen_body");
                  navigate("/teen-dashboard");
                }
              }}
            >
              {/* <option value="">
                Select User Role
              </option> */}
              <option value="dsa">Daily Security Advice</option>
              <option value="dsa_teen">Daily Security Advice for Teens</option>
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
