import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { supabase } from "../../supabase/supabaseClient";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { BsFillEyeFill } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { teenSupabase } from "../../supabase/teenSupabaseClient";

const TeenUsersTable = (props) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onDeleteUser = async () => {
    const users = await teenSupabase.auth.admin.listUsers();
    if (!users.error) {
      console.log("users: ", users.data.users);
      const deletingUser = users.data.users.filter(
        (user) => user?.email === props?.user?.email
      );
      console.log("deleting user: ", deletingUser[0]);
      const { data, error } = await teenSupabase.auth.admin.deleteUser(
        deletingUser[0]?.id
      );
      if (!error) {
        const updateUser = await teenSupabase
          .from("dsa_teen_users")
          .update({ deleted: true, is_premium: false })
          .eq("email", deletingUser[0]?.email)
          .select("*");
        if (!updateUser.error) {
          console.log("user updated in user table: ", updateUser.data);
          const deleteDeliveredEntries = await teenSupabase
            .from("dsa_teen_delivered_advices")
            .delete()
            .eq("user_id", updateUser.data[0]?.id);
          if (!deleteDeliveredEntries.error) {
            console.log(
              "deleted delivered advices: ",
              deleteDeliveredEntries.data
            );
            toast.success("User Deleted successfully ✅", {
              position: toast.POSITION.TOP_RIGHT,
              className: "bg-success text-white",
            });
            handleClose();
            props?.onDelete();
          } else {
            toast.error("Error while deleting User", {
              position: toast.POSITION.TOP_RIGHT,
              className: "bg-danger text-white",
            });
            handleClose();
            console.log(
              "failed to delete delivered advices: ",
              deleteDeliveredEntries.error
            );
          }
        } else {
          toast.error("Error while deleting User", {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-danger text-white",
          });
          handleClose();
          console.log("failed updating user in table: ", updateUser.error);
        }
      } else {
        toast.error("Error while deleting User", {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-danger text-white",
        });
        handleClose();
        console.log("Failed to delete user: ", error);
      }
    } else {
      console.log("error: ", users.error);
    }
  };

  return (
    <tr key={props?.advice?.id}>
      <td className="col-3 align-middle text-center">
        <div className="">
          {props?.user?.first_name + " " + props?.user?.last_name}
        </div>
      </td>
      <td className="col-4 text-center">
        <p className="advice__title">{props?.user?.email}</p>
      </td>
      <td className="col-4 text-center">
        <p
          className="advice__desc advice__category"
          style={{ backgroundColor: props?.user?.is_premium ? "green" : "" }}
        >
          {props?.user?.is_premium ? "Premium" : "Non-Premium"}
        </p>
      </td>
      <td className="col-1 text-end align-middle h-100">
        <div className="d-flex gap-2">
          <span
            onClick={() =>
              navigate(`/teen-users/${props?.user?.id}`, {
                state: { id: props?.user?.id },
              })
            }
          >
            <BsFillEyeFill />
          </span>
          <div className="delete_button" onClick={handleShow}>
            <RiDeleteBin6Line />
          </div>
        </div>
      </td>

      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header
          closeButton
          closeVariant="white"
          className="teen_sec border-0 text-white"
        >
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="teen_sec text-white">
          Are you sure you want to delete the User?
        </Modal.Body>
        <Modal.Footer className="teen_sec border-0">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={onDeleteUser}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </tr>
  );
};

export default TeenUsersTable;
