import React, { useEffect, useState } from "react";
import { supabase } from "../../supabase/supabaseClient";
import UsersTable from "../../components/UserTable/UsersTable";
import TeenUsersTable from "../../components/teenUserTable/teenUserTable";
import { teenSupabase } from "../../supabase/teenSupabaseClient";
import Processing from "../../components/processing/processing";
import { Button, Modal } from "react-bootstrap";
import { CSVLink } from "react-csv";

const TeenUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    setLoading(true);
    const { data, error } = await teenSupabase
      .from("dsa_teen_users")
      .select("*");
    if (!error) {
      setLoading(false);
      // console.log("user data: ", data);
      const filteredData = data.filter(
        (item) => !item?.deleted && item?.role !== "Admin"
      );
      // console.log("filter data: ", filteredData);
      setUsers(filteredData);
    } else {
      setLoading(false);
      console.log("error while fetching user data: ", error);
    }
  };

  const headers = [
    { label: "Name", key: "full_name" },
    { label: "Email", key: "email" },
    { label: "Type", key: "is_premium" },
    { label: "Total actions", key: "actions" },
  ];

  const csvReport = {
    data: reportData,
    headers: headers,
    filename: "TGSA_user_data.csv",
  };

  const onExport = async () => {
    const { data, error } = await teenSupabase
      .rpc("get_user_actions")
      .select("*");

    if (!error) {
      console.log("data: ", data);
      setReportData(data);
      handleShow();
    } else {
      console.log("error: ", error);
    }
  };

  return (
    <div className="dsa__users teen_sec">
      {loading && <Processing />}
      {/* header */}
      <div className="d-flex align-items-center justify-content-between">
        <div className="title">Users</div>
        <button
          className="dsa__advice__add teen_prim btn h-25"
          type="button"
          onClick={onExport}
        >
          Export
        </button>
      </div>

      {/* list */}
      <div className="advice__list mt-3">
        <table
          border={0}
          cellSpacing={0}
          className="advice__table teen_advice_table"
        >
          <thead>
            <tr>
              <th className=" text-center">Name</th>
              <th className=" text-center">Email</th>
              <th className="text-center">Type</th>
              <th className="text-end">Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.length !== 0 ? (
              users.map((user, index) => (
                <TeenUsersTable user={user} onDelete={getUsers} />
              ))
            ) : (
              <tr>
                <td className="text-center text-light fs-5" colSpan={4}>
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header
          closeButton
          closeVariant="white"
          className="primary_bg border-0 text-white"
        >
          <Modal.Title>Download Users</Modal.Title>
        </Modal.Header>
        <Modal.Body className="primary_bg text-white">
          Click "Download" to export all Users
        </Modal.Body>
        <Modal.Footer className="primary_bg border-0">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <CSVLink {...csvReport}>
            <Button variant="success" onClick={handleClose}>
              Download
            </Button>
          </CSVLink>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TeenUsers;
