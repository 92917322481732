import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo-ir.png";
import clsx from "clsx";
import * as Yup from "yup";
import { useFormik } from "formik";
import { supabase } from "../../supabase/supabaseClient";
import constants from "../../utils/constants";
import { useNavigate } from "react-router-dom";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("Password is required"),
});

const initialValues = {
  email: "",
  password: "",
};

const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      const users = await supabase.from("dsa_users").select("*");
      if (!users.error) {
        const isUser = users.data.filter(
          (user) =>
            user?.email?.toLowerCase() === values?.email?.toLowerCase() &&
            user?.role === "Admin"
        );
        if (isUser.length > 0) {
          try {
            const { data, error } = await supabase.auth.signInWithPassword({
              ...values,
            });
            if (!error) {
              localStorage.setItem(constants.USER, JSON.stringify(data.user));
              if (localStorage.getItem(constants.CURRENT_PANEL) === "dsa") {
                navigate("/");
              } else {
                navigate("/teen-dashboard");
              }
            } else {
              console.log("error: ", error);
              throw new Error(error);
            }
          } catch (error) {
            setStatus("The login details are incorrect");
            setSubmitting(false);
            setLoading(false);
          }
        } else {
          setStatus("The login details are incorrect");
          setSubmitting(false);
          setLoading(false);
        }
      }
    },
  });

  useEffect(() => {
    const USER = localStorage.getItem(constants.USER);
    if (USER) {
      navigate("/");
    }
  });

  return (
    <div className="Auth-form-container">
      <img src={logo} className="logo" alt="logo_image" />

      <p className="text-white fw-bold fs-3 mt-3">Daily Security Advice</p>

      <form className="Auth-form" onSubmit={formik.handleSubmit}>
        <div className="Auth-form-content">
          {/* title */}
          <h3 className="Auth-form-title">Login</h3>

          {/* username field */}
          <div className="form-group mt-3">
            <label>Email</label>
            <input
              type="text"
              {...formik.getFieldProps("email")}
              className={clsx(
                "form-control bg-transparent",
                {
                  "is-invalid": formik.touched.email && formik.errors.email,
                },
                {
                  "is-valid": formik.touched.email && !formik.errors.email,
                }
              )}
              placeholder="Enter email"
            />
            {formik.touched.email && formik.errors.email && (
              <div className="fv-plugins-message-container text-danger">
                <span role="alert">{formik.errors.email}</span>
              </div>
            )}
          </div>

          {/* password field */}
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              type="password"
              placeholder="Enter password"
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control bg-transparent",
                {
                  "is-invalid":
                    formik.touched.password && formik.errors.password,
                },
                {
                  "is-valid":
                    formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block text-danger">
                  <span role="alert">{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>

          {formik.status && (
            <div className="text-danger mt-1">{formik.status}</div>
          )}

          {/* submit button */}
          <div className="d-grid gap-2 mt-3">
            <button
              type="submit"
              id="kt_sign_in_submit"
              className="btn btn-primary"
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className="indicator-label">Continue</span>}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoginPage;
