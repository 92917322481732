import { TeenAdvice } from "../pages/teenAdvice/teenAdvice";
import TeenAdviceDetails from "../pages/teenAdviceDetails/teenAdviceDetails";
import TeenDashBoard from "../pages/teenDashboard/teenDashboard";
import TeenUsers from "../pages/teenUsers/teenUsers";
import TeenUserDetails from "../pages/teenUsersDetails/teenUsersDetails";
import PrivateRoute from "./routeProtection";

const teenRoutes = [
  {
    path: "/teen-dashboard",
    element: (
      <PrivateRoute>
        <TeenDashBoard />
      </PrivateRoute>
    ),
    title: "Dashboard for Teens Panel",
  },
  {
    path: "/teen-advice",
    element: (
      <PrivateRoute>
        <TeenAdvice />
      </PrivateRoute>
    ),
    title: "Advice for Teens Panel",
  },
  {
    path: "/teen-advice/:id",
    element: (
      <PrivateRoute>
        <TeenAdviceDetails />
      </PrivateRoute>
    ),
    title: "Advice Detail for Teens Panel",
  },
  {
    path: "/teen-users",
    element: (
      <PrivateRoute>
        <TeenUsers />
      </PrivateRoute>
    ),
    title: "Users for Teens Panel",
  },
  {
    path: "/teen-users/:id",
    element: (
      <PrivateRoute>
        <TeenUserDetails />
      </PrivateRoute>
    ),
    title: "User Detail for Teens Panel",
  },
];

export default teenRoutes;
