import React from 'react'

const Termscondition = () => {
    return (
        <>
            <section className='ds__privacy'>

                <div className="ds__hero">
                    <h2 className='text-white'>Terms & Condition</h2>
                </div>
                <div className="ds__content">
                    <p>At Daily Security Advice, we value the privacy and security of our users' personal information. This Privacy Policy outlines how we collect, use, and protect the data you provide while using our mobile application. By using Daily Security Advice, you consent to the practices described in this policy.</p>

                    <h4>1.	Information Collection and Usage:</h4>
                    <ul>
                        <li>
                            <p>Personal Information: We may collect personal information during the registration process, such as your name, email address, and social media account details if you choose to sign up using these methods. This information is used to personalize your experience, provide you with relevant security advice, and enhance the app's functionality.</p>
                        </li>
                        <li>
                            <p>Security Advice: The app provides daily security advice, which is collected and stored locally on your device. This advice is presented to you based on your preferences and usage patterns within the app. We do not collect or transmit this information to our servers.</p>
                        </li>
                        <li>
                            <p>Action Points and Completion: If you choose to mark an advice as requiring action, we store this data locally on your device in order to create and manage your personal list of action points. As you mark completed action points, the app updates the status locally on your device.</p>
                        </li>
                        <li>
                            <p>Analytics and Usage Data: We may collect anonymized usage data and analytics to improve the app's performance, enhance user experience, and track engagement patterns. This data includes information such as app usage, feature interaction, and device-specific details. We utilize industry-standard practices to ensure this data is securely transmitted and stored.</p>
                        </li>
                    </ul>


                    <h4>2.	Data Security:</h4>
                    <ul>
                        <li>
                            <p>Data Protection: We implement appropriate technical and organizational measures to safeguard your personal information against unauthorized access, alteration, disclosure, or destruction. We follow industry best practices to protect your data and regularly review and update our security protocols.</p>
                        </li>
                        <li>
                            <p>Third-Party Providers: In certain cases, we may utilize trusted third-party service providers to assist in delivering our services. These providers are carefully selected and are contractually bound to protect the confidentiality and security of your personal information in accordance with applicable privacy laws.</p>
                        </li>
                    </ul>


                    <h4>3.	User Choices:</h4>
                    <ul>
                        <li>
                            <p>Account Preferences: You can manage your account preferences within the app's settings, allowing you to customize notification timings and other features according to your preferences.</p>
                        </li>
                        <li>
                            <p>Data Deletion: You have the right to request the deletion of your personal information stored within the app. Deleting your account will remove all associated data, including action points and completion status. Please note that once deleted, this information cannot be recovered.</p>
                        </li>
                    </ul>

                    <h4>4.	Children's Privacy:</h4>
                    <ul>
                        <li>
                            <p>Daily Security Advice is intended for general audiences and does not knowingly collect personal information from children under the age of 13. If we become aware that we have inadvertently collected such information, we will take immediate steps to delete it.</p>
                        </li>
                    </ul>

                    <h4>5.	Changes to this Privacy Policy:</h4>
                    <ul>
                        <li>
                            <p>We reserve the right to modify this Privacy Policy at any time. Any updates will be communicated through the app or via email. By continuing to use the app after such changes, you signify your acceptance of the revised policy.</p>
                        </li>
                    </ul>

                    <h4>6.	Contact Us:</h4>
                    <ul>
                        <li>
                            <p>If you have any questions, concerns, or requests regarding this Privacy Policy or your personal information, please contact us at [insert contact information].</p>
                            <p>By using Daily Security Advice, you acknowledge that you have read and understood this Privacy Policy and consent to the collection, use, and storage of your personal information as described here in.</p>
                        </li>
                    </ul>
                </div>
            </section>
        </>
    )
}

export default Termscondition