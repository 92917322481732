import { Advice } from "../pages/advice/Advice";
import AdviceDetails from "../pages/advice_details/AdviceDetails";
import DashBoard from "../pages/dashboard/DashBoard";
import LoginPage from "../pages/login/LoginPage";
import UserDetails from "../pages/userDetails/userDetails";
import Users from "../pages/users/Users";
import PrivateRoute from "./routeProtection";

const routes = [
  {
    path: "/",
    element: (
      <PrivateRoute>
        <DashBoard />
      </PrivateRoute>
    ),
    title: "DashBoard",
  },
  {
    path: "/advice",
    element: (
      <PrivateRoute>
        <Advice />
      </PrivateRoute>
    ),
    title: "Advice",
  },
  {
    path: "/advice/:id",
    element: (
      <PrivateRoute>
        <AdviceDetails />
      </PrivateRoute>
    ),
    title: "Advice",
  },
  {
    path: "/users",
    element: (
      <PrivateRoute>
        <Users />
      </PrivateRoute>
    ),
    title: "Users",
  },
  {
    path: "/users/:id",
    element: (
      <PrivateRoute>
        <UserDetails />
      </PrivateRoute>
    ),
    title: "Users",
  },
];

export default routes;
