import React, { useState } from "react";
import { BsFillEyeFill } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import hideeye from "../../assets/svgs/hide.svg";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../supabase/supabaseClient";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { teenSupabase } from "../../supabase/teenSupabaseClient";

const TeenAdviceTable = (props) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showAdvice, setShowAdvice] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onShowHide = async () => {
    if (props?.advice?.deleted) {
      const updateDeliveredAdvice = await teenSupabase
        .from("dsa_teen_delivered_advices")
        .update({ deleted: false })
        .eq("advice_id", props?.advice?.id);
      if (!updateDeliveredAdvice.error) {
        const { data, error } = await teenSupabase
          .from("dsa_teen_advices")
          .update({ deleted: false })
          .eq("id", props?.advice?.id);
        if (!error) {
          // console.log("advice show success: ", data);
          toast.success("Advice status updated successfully ✅", {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-success text-white",
          });
          setShowAdvice(false);
          props?.onDelete();
        } else {
          // console.log("advice show failed: ", error);
          toast.error("Error while updating advice status", {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-danger text-white",
          });
          setShowAdvice(false);
        }
      } else {
        console.log("advice unhide error: ", updateDeliveredAdvice.error);
        toast.error("Error while updating advice status", {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-danger text-white",
        });
        setShowAdvice(false);
      }
    } else {
      const { data, error } = await teenSupabase
        .rpc("delete_advice", { ids: props?.advice?.id })
        .select("*");
      if (!error) {
        // console.log("advice status: ", !props?.advice?.deleted, " ", data);
        toast.success("Advice status updated successfully ✅", {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-success text-white",
        });
        setShowAdvice(false);
        props?.onDelete();
      } else {
        // console.log("advice hide/show error: ", error);
        toast.error("Error while updating advice status", {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-danger text-white",
        });
        setShowAdvice(false);
      }
    }
  };

  const onDeleteAdvice = async () => {
    const deleteDeliveredAdviceTableData = await teenSupabase.from("dsa_teen_delivered_advices").delete().eq("advice_id", props?.advice?.id);
    if(!deleteDeliveredAdviceTableData.error){
      const { data, error } = await teenSupabase
      .from("dsa_teen_advices")
      .delete()
      .eq("id", props?.advice?.id);
    if (!error) {
      // console.log("Advice deleted successfully: ", data);
      toast.success("Advice deleted successfully ✅", {
        position: toast.POSITION.TOP_RIGHT,
        className: "bg-success text-white",
      });
      handleClose();
      props?.onDelete();
    } else {
      // console.log("Error while deleting advice: ", error);
      toast.error("Error while deleting advice", {
        position: toast.POSITION.TOP_RIGHT,
        className: "bg-danger text-white",
      });
      handleClose();
    }
    } else {
      console.log("Error while deleting delivered table data: ", deleteDeliveredAdviceTableData.error);
      toast.error("Error while deleting advice", {
        position: toast.POSITION.TOP_RIGHT,
        className: "bg-danger text-white",
      });
      handleClose();
    }
    
  };

  return (
    <tr key={props?.advice?.id}>
      <td className="col-3 align-middle">
        <div className="advice__category">
          {props.advice?.category?.category}
        </div>
      </td>
      <td className="col-4">
        <p className="advice__title">{props?.advice?.title}</p>
      </td>
      <td className="col-4">
        <p className="advice__desc">{props?.advice?.description}</p>
      </td>
      <td className="col-1 text-end align-middle h-100">
        <div className="d-flex gap-2">
          {/* <span onClick={() => setShowAdvice(true)}>
            {props?.advice?.deleted ? (
              <img src={hideeye} alt="" width={18} />
            ) : (
              <BsFillEyeFill />
            )}
          </span> */}
          <span
            onClick={() =>
              navigate(`/teen-advice/${props?.advice?.id}`, {
                state: { id: props?.advice?.id },
              })
            }
          >
            <FiEdit />
          </span>
          <div className="delete_button" onClick={handleShow}>
            <RiDeleteBin6Line />
          </div>
        </div>
      </td>

      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header
          closeButton
          closeVariant="white"
          className="primary_bg border-0 text-white"
        >
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="primary_bg text-white">
          Are you sure you want to delete the advice?
        </Modal.Body>
        <Modal.Footer className="primary_bg border-0">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={onDeleteAdvice}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAdvice} centered onHide={() => setShowAdvice(false)}>
        <Modal.Header
          closeButton
          closeVariant="white"
          className="primary_bg border-0 text-white"
        >
          <Modal.Title>
            {props?.advice?.deleted
              ? "Show Advice Confirmation"
              : "Hide Advice Confirmation"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="primary_bg text-white">
          {props?.advice?.deleted
            ? "If you click 'Show', this advice will be visible to user. Are you sure?"
            : "If you click 'Hide', this advice will be hidden from users. Are you sure?"}
        </Modal.Body>
        <Modal.Footer className="primary_bg border-0">
          <Button variant="secondary" onClick={() => setShowAdvice(false)}>
            Close
          </Button>
          <Button
            variant={props?.advice?.deleted ? "success" : "danger"}
            onClick={onShowHide}
          >
            {props?.advice?.deleted ? "Show" : "Hide"}
          </Button>
        </Modal.Footer>
      </Modal>
    </tr>
  );
};

export default TeenAdviceTable;
