import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import * as Yup from "yup";


const ContactUs = () => {

    const [values, setValues] = useState({
        subject: '',
        message: ''
    })

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Please Enter Name"),
        email: Yup.string().email("Please enter valid email").required("Please Enter Email"),
    });
    // e 2 fiels mate validation ny mukta, wait

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
        },
        validationSchema: validationSchema,
        onSubmit: () => {
            if (validationSchema) {
                toast.success("Form Submitted successfully ✅", {
                    position: toast.POSITION.TOP_RIGHT,
                    className: "bg-success text-white",
                });
                setValues({
                    subject: '',
                    message: ''
                })
                formik.resetForm();
            } else {
                toast.error("Error while updating User Details", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        },
    });


    return (
        <>
            <section className='ds__privacy'>

                <div className="ds__hero">
                    <h2 className='text-white'>Contact Us</h2>
                </div>
                <div className="ds__content ds__contact_form">

                    <form action="">
                        <div className="form_control">
                            <input type="text" name="name" placeholder='Name*' id="" {...formik.getFieldProps("name")} />
                            {formik.touched.name && formik.errors.name && (
                                <div className="text-danger">{formik.errors.name}</div>
                            )}
                        </div>
                        <div className="form_control">
                            <input type="text" name="email" placeholder='Email*' id="" {...formik.getFieldProps("email")} />
                            {formik.touched.email && formik.errors.email && (
                                <div className="text-danger">{formik.errors.email}</div>
                            )}
                        </div>
                        <div className="form_control">
                            <input type="text" name="subject" placeholder='Subject' id="" value={values.subject} onChange={(e) => setValues((prev) => ({ ...prev, subject: e.target.value }))} />
                        </div>
                        <div className="form_control">
                            <textarea name="message" placeholder='Message' id="" cols="30" rows="10" value={values.message} onChange={(e) => setValues((prev) => ({ ...prev, message: e.target.value }))}></textarea>
                        </div>
                        <button onClick={formik.handleSubmit}>Send Message</button>
                    </form>

                </div>
            </section>
        </>
    )
}

export default ContactUs