import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { supabase } from "../../supabase/supabaseClient";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Processing from "../../components/processing/processing";
import AddTeenAdviceModal from "../../components/addTeenAdviceModal/addTeenAdviceModal";
import TeenAdviceTable from "../../components/teenAdviceTable/teenAdviceTable";
import { teenSupabase } from "../../supabase/teenSupabaseClient";

export const TeenAdvice = () => {
  const [advices, setAdvices] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAdvice();
  }, []);

  const getAdvice = async () => {
    setLoading(true);
    const { data, error } = await teenSupabase
      .from("dsa_teen_advices")
      .select("id, title, description, category(*), deleted")
      .order("id", { ascending: true });
    if (!error) {
      setLoading(false);
      // console.log("advice data: ", data);
      const filteredData = data.filter((item) => !item.deleted);
      // console.log("advice data:2 ", filteredData);
      setAdvices(data);
    } else {
      setLoading(false);
      console.log("error while fetching advice data: ", error);
    }
  };

  return (
    <div className="dsa__advice teen_sec">
      {loading && <Processing />}
      {/* header */}
      <div className="d-flex align-items-center justify-content-between">
        <div className="title">Advice</div>
        <button
          className="dsa__advice__add teen_prim btn h-25"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#addAdviceModal"
        >
          Add
        </button>
      </div>

      <AddTeenAdviceModal onAdd={() => getAdvice()} />

      {/* list */}
      <div className="advice__list mt-3">
        <table
          border={0}
          cellSpacing={0}
          className="advice__table teen_advice_table"
        >
          <thead>
            <tr>
              <th>Category</th>
              <th>Title</th>
              <th>Details</th>
              <th className="text-end">Actions</th>
            </tr>
          </thead>
          <tbody>
            {advices.length !== 0 ? (
              advices.map((advice, index) => (
                <TeenAdviceTable advice={advice} onDelete={getAdvice} />
              ))
            ) : (
              <tr>
                <td className="text-center text-light fs-5" colSpan={4}>
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
