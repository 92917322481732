import React, { useEffect, useState } from "react";
import { Chart } from "chart.js";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics"; // Function to retrieve Firebase Analytics data

const AnalyticsChart = () => {
  const [chartData, setChartData] = useState(null);

  const app = initializeApp({
    // Replace with your Firebase configuration
    apiKey: "AIzaSyDyOiROu_r6Wm1o2PNHPxGLd5pz0H58EOs",
    projectId: "daily-security-advice-a7dda",
    appId: "1:663674325737:android:86fc4135b9e60ec1f1fe78",
  });

  const getAnalyticsData = () => {
    const analytics = getAnalytics(app);
    console.log("analytics data: ", analytics);
    setChartData(analytics);
  };

  useEffect(() => {
    getAnalyticsData();
  }, []);

  useEffect(() => {
    if (chartData) {
      const ctx = document.getElementById('analyticsChart').getContext('2d');

      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: chartData.labels,
          datasets: [
            {
              label: 'Event Count',
              data: chartData.eventCount,
              backgroundColor: 'rgba(75, 192, 192, 0.6)',
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
            //   type: 'linear', // Use the default linear scale
              beginAtZero: true,
            },
          },
        },
      });
    }
  }, [chartData]);

  return <canvas id="analyticsChart" />;
};

export default AnalyticsChart;
