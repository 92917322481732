import React, { useEffect, useState } from "react";
import "./css/style.css";
import routes from "./routes/route";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Error from "./pages/error/error";
import Sidebar from "./components/sidebar/Sidebar";
import constants from "./utils/constants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivacyPolicy from "./pages/privacypolicy/privacyPolicy";
import Termscondition from "./pages/termscondition/termscondition";
import ContactUs from "./pages/contact_us/contactUs";
import LoginPage from "./pages/login/LoginPage";
import teenRoutes from "./routes/teenRoute";

// main component
function App() {
  const currentPath = useLocation();
  const navigate = useNavigate();
  if (localStorage.getItem(constants.CURRENT_PANEL) === null) {
    localStorage.setItem(constants.CURRENT_PANEL, "dsa");
  }
  useEffect(() => {
    // if (!localStorage.getItem(constants.USER)) {
    //   navigate("/login");
    // }
    if (localStorage.getItem(constants.CURRENT_PANEL) === "dsa_teen") {
      document.body.classList.add("dsa_teen_body");
      if (!currentPath.pathname.includes("/teen")) {
        console.log("reached?");
        localStorage.setItem(constants.CURRENT_PANEL, "dsa");
        document.body.classList.remove("dsa_teen_body");
        window.location.reload();
      } else {
        if (currentPath.pathname === "/teen-dashboard") {
          navigate("/teen-dashboard");
        } else if (currentPath.pathname === "/teen-advice") {
          navigate("/teen-advice");
        } else if (currentPath.pathname === "/teen-users") {
          navigate("/teen-users");
        }
      }
    }
    if (localStorage.getItem(constants.CURRENT_PANEL) === null) {
      localStorage.setItem(constants.CURRENT_PANEL, "dsa");
      navigate("/login");
    }
  }, []);
  return (
    <>
      <ToastContainer />
      {currentPath.pathname !== "/login" &&
        currentPath.pathname !== "/404" &&
        currentPath.pathname !== "/privacy-and-policy" &&
        currentPath.pathname !== "/terms-condition" &&
        currentPath.pathname !== "/contact-us" && <Sidebar />}
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        {localStorage.getItem(constants.CURRENT_PANEL) === "dsa" &&
          routes.map(({ path, element, title }) => (
            <Route key={title} path={path} element={element} />
          ))}
        {localStorage.getItem(constants.CURRENT_PANEL) === "dsa_teen" &&
          teenRoutes.map(({ path, element, title }) => (
            <Route key={title} path={path} element={element} />
          ))}
        <Route path="/privacy-and-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-condition" element={<Termscondition />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/404" element={<Error />} />

        {/* <Route path="*" element={<Navigate to="/404" replace />} /> */}
      </Routes>
    </>
  );
}

export default App;
