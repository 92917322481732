import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { supabase } from "../../supabase/supabaseClient";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { teenSupabase } from "../../supabase/teenSupabaseClient";

const AddTeenAdviceModal = (props) => {
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    const { data, error } = await teenSupabase
      .from("dsa_teen_categories")
      .select("*");
    if (!error) {
      //   console.log("categories: ", data);
      setCategories(data);
      formik.setFieldValue("category", data[0]?.id);
    } else {
      console.log("error fetching categories: ", error);
    }
  };

  const validationSchema = Yup.object().shape({
    category: Yup.string().required("Please select Advice Category"),
    title: Yup.string().required("Please Enter Advice Title"),
    description: Yup.string().required("Please Enter Advice Details"),
  });

  const formik = useFormik({
    initialValues: {
      category: null,
      title: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("add advice values: ", values);
      const advices = await teenSupabase
        .from("dsa_teen_advices")
        .select("id")
        .order("id", { ascending: true });
      if (!advices.error) {
        // console.log("advices data: ", advices.data[advices.data.length - 1]);
        const { data, error } = await teenSupabase
          .from("dsa_teen_advices")
          .insert({
            ...values,
            id: advices.data[advices.data.length - 1]?.id + 1,
          });
        if (!error) {
          toast.success("Advice added successfully ✅", {
            position: toast.POSITION.TOP_RIGHT,
            className: "bg-success text-white",
          });
          document.getElementById("close__button").click();
          props?.onAdd();
        } else {
          toast.error("Error while adding Advice", {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.log("error white updating advice details: ", error);
        }
      } else {
        toast.error("Error while adding Advice", {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log("advices error: ", advices.error);
      }
    },
  });

  return (
    <div
      id="addAdviceModal"
      className="modal modal-content-scrollable"
      tabIndex={-1}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content ">
          <div className="modal-header teen_sec text-white border-0">
            <h5 className="modal-title">Add Advice</h5>
            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="close__button"
            ></button>
          </div>
          <div className="modal-body teen_sec">
            <div className="d-flex flex-column">
              <label className="category_label">Category</label>
              <select
                className=""
                name="category"
                {...formik.getFieldProps("category")}
                disabled
                defaultValue={categories[0]?.id}
              >
                <option value="">Select Advice Category</option>
                {categories &&
                  categories.map((category, index) => (
                    <option key={category?.category} value={category?.id}>
                      {category?.category}
                    </option>
                  ))}
              </select>
            </div>

            <div className="d-flex flex-column mt-3">
              <label className="category_label">Title</label>
              <input
                type="text"
                name="title"
                placeholder="Enter Advice Title"
                className="form form-control bg-transparent text-white add_inputs"
                value={formik.values.title}
                onChange={(e) =>
                  formik.setFieldValue("title", e.target.value.trimStart())
                }
              />
              {formik.touched.title && formik.errors.title && (
                <div className="text-danger">{formik.errors.title}</div>
              )}
            </div>
            <div className="d-flex flex-column mt-3">
              <label className="category_label">Details</label>
              <textarea
                type="text"
                name="description"
                placeholder="Enter Advice Details"
                aria-multiline="true"
                className="form form-control bg-transparent text-white add_inputs"
                value={formik.values.description}
                onChange={(e) =>
                  formik.setFieldValue(
                    "description",
                    e.target.value.trimStart()
                  )
                }
              />
              {formik.touched.description && formik.errors.description && (
                <div className="text-danger">{formik.errors.description}</div>
              )}
            </div>
          </div>
          <div className="modal-footer border-0 teen_sec">
            <button
              type="button"
              className="btn btn-secondary border-0"
              //   data-bs-dismiss="modal"
              onClick={() => {
                formik.resetForm();
                formik.setFieldValue("category", categories[0]?.id);
              }}
            >
              Clear
            </button>
            <button
              type="button"
              className="btn teen_prim text-white"
              onClick={formik.handleSubmit}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTeenAdviceModal;
