import React, { useEffect, useState } from "react";
import { supabase } from "../../supabase/supabaseClient";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Processing from "../../components/processing/processing";
import { useLocation, useNavigate } from "react-router-dom";
import { teenSupabase } from "../../supabase/teenSupabaseClient";

const TeenUserDetails = (props) => {
  const [isEditable, setIsEditable] = useState(false);
  const [categories, setCategories] = useState([]);
  const [buttonValue, setButtonValue] = useState("Edit");
  const [userDetails, setUserDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = state;

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    setLoading(true);
    const { data, error } = await teenSupabase
      .from("dsa_teen_users")
      .select("*")
      .eq("id", id);
    if (!error) {
      setLoading(false);
      //   console.log("data: ", data);
      setUserDetails(data);
      formik.setFieldValue("first_name", data[0]?.first_name);
      formik.setFieldValue("last_name", data[0]?.last_name);
      formik.setFieldValue("email", data[0]?.email);
      formik.setFieldValue("role", data[0]?.role);
    } else {
      setLoading(false);
      console.log("error fetching user details: ", error);
    }
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("Please enter first name"),
    last_name: Yup.string().required("Please enter last name"),
    email: Yup.string().required("Please enter email"),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      role: "User",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values: ", values);
      const { data, error } = await teenSupabase
        .from("dsa_teen_users")
        .update({ ...values })
        .eq("id", id);
      if (!error) {
        toast.success("User Details updated successfully ✅", {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-success text-white",
        });
        navigate("/teen-users");
      } else {
        toast.error("Error while updating User Details", {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log("error white updating User details: ", error);
      }
    },
  });

  const enableEditing = () => {
    setIsEditable(true);
    setButtonValue("Update");
  };

  return (
    <div className="dsa__advice teen_sec pb-5">
      {loading && <Processing />}
      {/* header */}
      <div className="title">User Details</div>

      <div className="advice_form">
        <div className="d-flex">
          <div className="d-flex flex-column pe-2 col-6 mt-3">
            <label className="category_label">First Name</label>
            <input
              type="text"
              name="first_name"
              disabled={isEditable ? false : true}
              className="form form-control bg-transparent text-white"
              value={formik.values.first_name}
              onChange={(e) =>
                formik.setFieldValue(
                  "first_name",
                  e.target.value.replace(/[0-9]/g, "").trimStart()
                )
              }
            />
            {formik.touched.first_name && formik.errors.first_name && (
              <div className="text-danger">{formik.errors.first_name}</div>
            )}
          </div>

          <div className="d-flex flex-column ps-2 col-6 mt-3">
            <label className="category_label">Last Name</label>
            <input
              type="text"
              name="last_name"
              disabled={isEditable ? false : true}
              className="form form-control bg-transparent text-white"
              value={formik.values.last_name}
              onChange={(e) =>
                formik.setFieldValue(
                  "last_name",
                  e.target.value.replace(/[0-9]/g, "").trimStart()
                )
              }
            />
            {formik.touched.last_name && formik.errors.last_name && (
              <div className="text-danger">{formik.errors.last_name}</div>
            )}
          </div>
        </div>
        <div className="d-flex flex-column mt-3">
          <label className="category_label">Email</label>
          <input
            type="text"
            name="email"
            disabled={true}
            className="form form-control bg-transparent text-white"
            {...formik.getFieldProps("email")}
          />
          {/* {formik.touched.title && formik.errors.title && (
            <div className="text-danger">{formik.errors.title}</div>
          )} */}
        </div>
        <div className="d-flex flex-column mt-3">
          <label className="category_label">User</label>
          <select
            className="category_dropdown form-select"
            name="role"
            {...formik.getFieldProps("role")}
            disabled={true}
          >
            <option value="">Select User Role</option>
            <option value={"Admin"}>{"Admin"}</option>
            <option value={"User"}>{"User"}</option>
          </select>
        </div>

        <div className="d-flex mt-5 justify-content-end gap-4">
          <button
            className="form_button teen_prim btn"
            onClick={() => {
              window.history.back();
            }}
          >
            Back
          </button>
          <button
            className="form_button teen_prim btn"
            // disabled={
            //   buttonValue === "Update" &&
            //   formik.values.title === adviceDetails[0]?.title &&
            //   formik.values.description === adviceDetails[0]?.description &&
            //   formik.values.category === adviceDetails[0]?.dsa_categories?.id
            // }
            onClick={
              buttonValue === "Update" ? formik.handleSubmit : enableEditing
            }
          >
            {buttonValue}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TeenUserDetails;
