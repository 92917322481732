import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { supabase } from "../../supabase/supabaseClient";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Processing from "../../components/processing/processing";
import { teenSupabase } from "../../supabase/teenSupabaseClient";

const TeenAdviceDetails = () => {
  const { state } = useLocation();
  const { id } = state;
  const navigate = useNavigate();

  const [isEditable, setIsEditable] = useState(false);
  const [categories, setCategories] = useState([]);
  const [adviceDetails, setAdviceDetails] = useState([]);
  const [buttonValue, setButtonValue] = useState("Edit");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCategories();
    getAdviceDetails();
  }, []);

  const getAdviceDetails = async () => {
    setLoading(true);
    const { data, error } = await teenSupabase
      .from("dsa_teen_advices")
      .select("id, title, description, dsa_teen_categories(id, category)")
      .eq("id", id);
    if (!error) {
      setLoading(false);
      //   console.log("data: ", data);
      setAdviceDetails(data);
      formik.setFieldValue("title", data[0]?.title);
      formik.setFieldValue("description", data[0]?.description);
      formik.setFieldValue("category", data[0]?.dsa_teen_categories.id);
    } else {
      setLoading(false);
      console.log("error fetching advice details: ", error);
    }
  };

  const getCategories = async () => {
    setLoading(true);
    const { data, error } = await teenSupabase
      .from("dsa_teen_categories")
      .select("*");
    if (!error) {
      setLoading(false);
      //   console.log("categories: ", data);
      setCategories(data);
    } else {
      setLoading(false);
      console.log("error fetching categories: ", error);
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Please Enter Advice Title"),
    description: Yup.string().required("Please Enter Advice Details"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      category: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      //   console.log("values: ", values);
      const { data, error } = await teenSupabase
        .from("dsa_teen_advices")
        .update({ ...values })
        .eq("id", id);
      if (!error) {
        toast.success("Advice Details updated successfully ✅", {
          position: toast.POSITION.TOP_RIGHT,
          className: "bg-success text-white",
        });
        navigate("/teen-advice");
      } else {
        toast.error("Error while updating Advice Details", {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log("error white updating advice details: ", error);
      }
    },
  });

  const enableEditing = () => {
    setIsEditable(true);
    setButtonValue("Update");
  };

  return (
    <div className="dsa__advice teen_sec pb-5">
      {loading && <Processing />}
      {/* header */}
      <div className="title">Advice Details</div>

      <div className="advice_form">
        <div className="d-flex flex-column">
          <label className="category_label">Category</label>
          <select
            className="category_dropdown form-select"
            name="category"
            {...formik.getFieldProps("category")}
            disabled={true}
          >
            <option value="" disabled>
              Select Advice Category
            </option>
            {categories &&
              categories.map((category, index) => (
                <option key={category?.category}>{category?.category}</option>
              ))}
          </select>
        </div>

        <div className="d-flex flex-column mt-3">
          <label className="category_label">Title</label>
          <input
            type="text"
            name="title"
            disabled={isEditable ? false : true}
            className="form form-control bg-transparent text-white"
            value={formik.values.title}
            onChange={(e) =>
              formik.setFieldValue("title", e.target.value.trimStart())
            }
          />
          {formik.touched.title && formik.errors.title && (
            <div className="text-danger">{formik.errors.title}</div>
          )}
        </div>
        <div className="d-flex flex-column mt-3">
          <label className="category_label">Details</label>
          <textarea
            type="text"
            disabled={isEditable ? false : true}
            name="description"
            aria-multiline="true"
            className="form form-control bg-transparent text-white"
            value={formik.values.description}
            onChange={(e) =>
              formik.setFieldValue("description", e.target.value.trimStart())
            }
          />
          {formik.touched.description && formik.errors.description && (
            <div className="text-danger">{formik.errors.description}</div>
          )}
        </div>

        <div className="d-flex mt-5 justify-content-end gap-4">
          <button
            className="form_button teen_prim btn"
            onClick={() => {
              window.history.back();
            }}
          >
            Back
          </button>
          <button
            className="form_button teen_prim btn"
            disabled={
              buttonValue === "Update" &&
              formik.values.title === adviceDetails[0]?.title &&
              formik.values.description === adviceDetails[0]?.description &&
              formik.values.category === adviceDetails[0]?.dsa_categories?.id
            }
            onClick={
              buttonValue === "Update" ? formik.handleSubmit : enableEditing
            }
          >
            {buttonValue}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TeenAdviceDetails;
