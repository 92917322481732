import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_DB_URL_TEEN;
const supabaseKey = process.env.REACT_APP_DB_KEY_TEEN;
export const teenSupabase = createClient(supabaseUrl, supabaseKey, {
  auth: {
    autoRefreshToken: false,
    persistSession: false,
  },
});
